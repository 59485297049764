import styles from './Static.module.scss';

const Privacy = () => {

  return (
    <>
      <h1 className={`mb-3 text-center ${styles.header}`}>Privacy policy</h1>
      <p className="mt-3">
        This Privacy Policy describes how Exact IOT Limited ("we", "us", or "our") collects, uses, and shares personal information when you use this website (the "Website") and the services provided therein (the "Service").
      </p>

      <h4>1. Information We Collect</h4>
      <p className="mt-3">
        1.1. Information You Provide: When you register for an account, use the Service, or communicate with us, we may collect personal information such as your name, email address, phone number and any other information you choose to provide.
      </p>
      <p className="mt-3">
        1.2. Automatically Collected Information: We may automatically collect certain information about your device, including your IP address, browser type, and operating system.
      </p>
      <p className="mt-3">
        1.3. Cookies: We may use cookies and similar tracking technologies to collect information about your interactions with the Website. You can control cookies through your browser settings and other tools.
      </p>


      <h4>2. Use of Information</h4>
      <p className="mt-3">
        2.1. We may use the information we collect for various purposes, including to:
      
        <ul>
          <li>Provide and maintain the Service;</li>
          <li>Communicate with you about the Service;</li>
          <li>Customize and improve the Service;</li>
          <li>Analyze how the Service is used;</li>
          <li>Protect the security and integrity of the Service.</li>
        </ul>
      </p>


      <h4>3. Sharing of Information</h4>
      <p className="mt-3">
        3.1. We may share personal information with third-party service providers who assist us in operating the Service, conducting our business, or servicing you.
      </p>
      <p className="mt-3">
        3.2. We may also share personal information in response to legal process, to protect our rights or property, or to prevent illegal activities.
      </p>

      <h4>4. Data Retention</h4>
      <p className="mt-3">
        4.1. We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
      </p>

      <h4>5. Your Choices</h4>
      <p className="mt-3">
        5.1. You may opt-out of receiving promotional emails from us by following the instructions provided in those emails. Please note that even if you opt-out, you may still receive non-promotional emails related to your account or transactions.
      </p>


      <h4>6. Security</h4>
      <p className="mt-3">
        6.1. We take reasonable measures to protect the personal information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.
      </p>
      <p className="mt-3">
        6.2. Our system is built on Microsoft Azure cloud servers, renowned for their security measures and compliance with privacy standards.
      </p>
      <p className="mt-3">
        6.3. Access to the Service is tightly controlled. Individuals must register and get approval before gaining access. Once approved, users can securely log in to the system and access details only for their assigned Playcentres.
      </p>


      <h4>7. Changes to this Privacy Policy</h4>
      <p className="mt-3">
        7.1. We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>

      <h4>8. Contact Us</h4>
      <p className="mt-3">
        8.1. If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@exact-iot.com">support@exact-iot.com</a>
      </p>
    </>
  );
}

export { Privacy };