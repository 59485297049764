import { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useParams } from 'react-router-dom';


export default function MemberListForTerm() {
  const { termYear, termIndex } = useParams();


  const [loading, setLoading] = useState(true);

  let [memberList, setMemberList] = useState([]);
  let [term, setTerm] = useState();
  let [pc, setPc] = useState();

  useEffect(() => {
    const loadMembers = () => {
      api
        .get(`/term/${termYear}/${termIndex}/members/list`)
        .then((response) => {
          setMemberList(response.data.members);          
          setTerm(response.data.term);
          setPc(response.data.playcentre);

          setLoading(false);
        });
    };
    loadMembers();    

  }, [termYear, termIndex]);


  if (loading)
    return <Loading />;

  return (
    <>

      <table className="table table-striped">
        <thead>
          <tr>
            <th colSpan={9} className="text-center">
              <h3>Contact list</h3>
              <h5>{pc.displayName}</h5>
              <h5>{term.displayName}, {term.termDates}</h5>
            </th>
          </tr>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Qualification</th>
            <th>First Aid Expiry</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {memberList.map((member, index) => (
            <tr key={member.uid}>
              <td>{index + 1}</td>
              <td>{member.displayName}</td>
              <td>{member.rosterEducationName}</td>
              <td>{member.firstAidExpiryDate}</td>
              <td>{member.email}</td>
              <td>{member.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
}

