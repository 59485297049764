import React, { useState, useEffect } from "react";

import { useTranslation, Trans } from 'react-i18next';

import api from "../../services/api";

import { Form, Button, Alert } from "react-bootstrap"
import { Formik } from "formik";
import * as Yup from "yup";

import styles from './Auth.module.scss';


const ForgotPasswordRequest = (props) => {
  const { t } = useTranslation();
  
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
 // let navigate = useNavigate();

  //const [username, setUsername] = useState("");
  //const [password, setPassword] = useState("");

  const handleLogin = async (data, form) =>  {
    //console.log(data); console.log(form);
    
    setMessage("");
    setLoading(true);

    api.post("/auth/reset-password", {
      email: data.email,      
    },
      { formik: form, setErrorMessage: setMessage }
    )
      .then(response => {
        setRedirect(true)
      })
      .catch((error) => { setLoading(false); }); 
  };

  useEffect(() => {

   
  }, []);

  return (
    <>
      {redirect && (
        <Alert key="success" variant="success">
          <Trans i18nKey="forgotPassword.sentSuccess">
            If the email you entered is registered in the system, we have sent you a link to re-set your password. Go to <Alert.Link href="/login">login</Alert.Link>.
          </Trans>
        </Alert>
      )}

      {!redirect && (
        <>
          <h1 className={`mb-3 text-center ${styles.header}`}>{t('forgotPassword.header')}</h1>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(t("validation.invalidEmail"))
                .required(t("validation.required")),
            })}
            onSubmit={handleLogin}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                
                <Form.Group controlId="validationFormik01" className={`form-floating ${styles.inputWrapper}`}>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="name@example.com"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                    className={styles.input}
                    aria-describedby="emailHelpBlock"
                  />
                  <Form.Label>{t('forgotPassword.yourEmail')}</Form.Label>
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.email}</Form.Control.Feedback>
                  <Form.Text id="emailHelpBlock" muted>
                    {t('forgotPassword.emailHeader')}
                  </Form.Text>
                </Form.Group>                
                
                <Button className={`w-100 btn-lg ${styles.submitButton}`} type="submit" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span> {t('forgotPassword.send')}</span>
                </Button>
                {message && (
                  <Alert key="danger" variant="danger" className="mt-3">
                    {message}
                  </Alert>
                )}
              </Form>
            )}
          </Formik>

          <p className="mt-3 text-center">
            <Trans i18nKey="forgotPassword.goToLogin">
              or <a href="/login">go back</a> to the login page
            </Trans>
          </p>
        </>)}

    </>    
  );
}

export { ForgotPasswordRequest };