import React, { Component } from 'react';

import AuthService from '../services/auth.service';
export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Hi, {AuthService.getLoggedInUser()?.firstName}</h1>
        <br />

        <h4>Welcome to the new roster system for <b>{AuthService.getLoggedInUser()?.playcentre?.displayName}</b>!</h4>
        <br />
        <p><i>For optimal experience, we recommend using a laptop due to the presence of large tables that require efficient display.</i></p>

        <br />

        <p>Here, you'll find it easy to manage your member list, create rosters, and download reports.</p>
        
        <p>Should you have any questions or need assistance, don't hesitate to reach out to the developer at <a href="mailto:playcentre@exact-iot.com?subject=Playcentre Roster question">playcentre@exact-iot.com</a></p>

        <br />
        <p><b>To begin creating a roster:</b></p>
        <ol>
          <li>Enter members' details and their kids under "Members" -{'>'} <a href="/settings/members/list">"Edit playcentre members"</a></li>
          <li>Select an active term from the dropdown at the top of the page.</li>
          <li>Fill in member days under "Roster" -{'>'} "Edit roster", ensuring to select attending kids.</li>
          <li>View the result under "Roster" -{'>'} "View roster". You can print this page (right-click -{'>'} Print) or use the 'Save to PDF' option from the print dialog.</li>
        </ol>

        <br />

        <p><b>Once your roster is complete:</b></p>
        <p>Explore available reports under the "Members" or "Kids" menu and download them for further use or to send to Lisa.</p>
        <br />

        <p><b>Here are some tips for using available reports:</b></p>
        <ul>
          <li>All reports available for download will be saved as Excel files.</li>
          <li>If a report contains multiple sheets (such as 'Change of days of enrolment' or 'Day book'), remember to select all sheets when printing or converting to PDF.</li>
          <li>To print a roster, right-click and select 'Print'. Then, either print or use the 'Save to PDF' option from the print dialog.</li>
        </ul>
        <p></p>

        <br />

        <p>Enjoy!</p>
      </div>




    );
  }
}
