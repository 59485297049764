import React, { Component } from 'react';
import { NavMenuSimple } from '../Nav/NavMenuSimple';

import { Container, Row, Col } from 'react-bootstrap';

import { Outlet } from 'react-router-dom';
import styles from './LayoutAuth.module.scss'

export class LayoutStatic extends Component {
  static displayName = LayoutStatic.name;

  render() {
    return (
      <>
        <div className={`${styles.root}`}>
          <div className={styles.header}>
            <NavMenuSimple />
          </div>
          <Container fluid="xl" className={`${styles.main} h-100`}>
            <Row className="h-100">
              <Col className="mt-auto mb-auto" >
                <main>
                  <div className={styles.authBox}>
                    <Outlet />
                  </div>
                </main>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
