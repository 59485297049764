import { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useParams } from 'react-router-dom';


export default function KidsByDay() {
  const { termYear, termIndex } = useParams();


  const [loading, setLoading] = useState(true);

  let [kidsList, setKidsList] = useState([]);
  let [term, setTerm] = useState();
  let [pc, setPc] = useState();

  useEffect(() => {
    const loadMembers = () => {
      api
        .get(`/term/${termYear}/${termIndex}/kids/days`)
        .then((response) => {
          setKidsList(response.data.kids);          
          setTerm(response.data.term);
          setPc(response.data.playcentre)
          setLoading(false);
        });
    };
    loadMembers();    

  }, [termYear, termIndex]);


  if (loading)
    return <Loading />;

  const fillCell = (data) => (data && data.length > 0) ? "9:00 - 12:00" : "";

  return (
    <>
      
      <table className="table table-striped">
        <thead>
          <tr>
            <th colSpan={9} className="text-center">
              <h3>Enrolment report</h3>
              <h5>{pc.displayName}</h5>
              <h5>{term.displayName}, {term.termDates}</h5>
            </th>
          </tr>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Age</th>
            <th>Parent name</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
          </tr>
        </thead>
        <tbody>
          {kidsList.map((line, index) => (
            <tr key={line.kid.uid}>
              <td>{index + 1}</td>
              <td>{line.kid.displayName}</td>
              <td>{line.kid.ageFull}</td>
              <td>{line.kid.member.displayName}</td>
              <td>{fillCell(line.monday)}</td>
              <td>{fillCell(line.tuesday)}</td>
              <td>{fillCell(line.wednesday)}</td>
              <td>{fillCell(line.thursday)}</td>
              <td>{fillCell(line.friday)}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
}

