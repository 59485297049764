class TokenService {
  isUserLoggedIn() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    return user !== undefined;
  }
  getUserUid() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.uid;
  }
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.jwtToken;
  }
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user === null) user = {};
    user.jwtToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  setUser(user) {    
    localStorage.setItem("user", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("user");
  }
}
export default new TokenService();