import axios from "axios";
import TokenService from "./token.service";
import { ConcurrentActionHandler } from './concurrentActionHandler';

const _concurrencyHandler = new ConcurrentActionHandler();

const instance = axios.create({
  //baseURL: "http://localhost:8080/api",
  baseURL: "/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }

    config.headers["Accept-Language"] = getCookie("i18next-lng");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig && originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        console.log('Got status 401, waiting for a new token for request.. ', originalConfig.url);

        try {
          await _concurrencyHandler.execute(async () => {
            const rs = await instance.post("/auth/refresh-token", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });
            //const { jwtToken } = rs.data;
            //TokenService.updateLocalAccessToken(jwtToken);
            TokenService.setUser(rs.data);
          });

          console.log('Repeating request.. ', originalConfig.url);
          return instance(originalConfig);
        } catch (_error) {
          console.log('caught api error:', _error);
          if (_error.response.status === 400)
            window.location.href = "/login";

          return Promise.reject(_error);
        }
      }
    }

    if (err.response.status === 403 && err.response.data && err.response.data.code === 501) {
      window.location.href = "/waiting";
    }
    //if (err.response.status === 422 && err.response.data && err.response.data.errorCode === 101) {
    //  console.log("Validation error");
    //  console.log(err.response.data);
    //  let customError = new Error(err.response.data);
    //  //response.original_status = response.status
    //  //response.status = response.data.errors[0].extensions.code
    //  //customError.response = response;
    //  return Promise.reject(customError);
    //}

    if (err.response && err.response.data && err.response.status === 422 && (err.config.formik || err.config.setErrorMessage)) {
      let errorData = err.response.data;
      if (errorData && errorData.errorCode === 101)
        if (err.config.formik)
          err.config.formik.setErrors(errorData.errors);
        else {
          if (err.config.setErrorMessage && typeof err.config.setErrorMessage === 'function') {
            let resMessage =
              (errorData && errorData.message) ||
              err.message ||
              err.toString();
            err.config.setErrorMessage(resMessage);
          }
        }
      if (errorData && (errorData.errorCode === 102 || errorData.errorCode === 103)) {
        if (err.config.setErrorMessage && typeof err.config.setErrorMessage === 'function') {
          let resMessage =
            (errorData && errorData.message) ||
            err.message ||
            err.toString();
          err.config.setErrorMessage(resMessage);
        }
        if (err.config.formik && err.config.specificErrorField) {
          let resMessage =
            (errorData && errorData.message) ||
            err.message ||
            err.toString();
          console.log(resMessage);
          err.config.formik.setFieldError(err.config.specificErrorField, resMessage);
        }
      }
    }

    return Promise.reject(err);
  }
);

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default instance;