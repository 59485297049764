import { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useParams } from 'react-router-dom';

import styles from './MemberSupervisionPlan.module.scss';

export default function MemberSupervisionPlan() {
  const { termYear, termIndex } = useParams();


  const [loading, setLoading] = useState(true);

  let [memberList, setMemberList] = useState([]);
  let [term, setTerm] = useState();
  let [totalKids, setTotalKids] = useState();
  let [totalKidsUnder2, setTotalKidsUnder2] = useState();
  let [pc, setPc] = useState();

  useEffect(() => {
    const loadMembers = () => {
      api
        .get(`/term/${termYear}/${termIndex}/members/supervision`)
        .then((response) => {
          setMemberList(response.data.members.filter(x => x.member.rosterEducationName !== ''));    
          setTerm(response.data.term);
          setTotalKids(response.data.totalKids);
          setTotalKidsUnder2(response.data.totalKidsUnderTwo);
          setPc(response.data.playcentre);

          setLoading(false);
        });
    };
    loadMembers();    

  }, [termYear, termIndex]);


  if (loading)
    return <Loading />;

  //const fillCell = (data) => (data && data.length > 0) ? "9:00 - 12:00" : "";
  const fillCell = (data) => (data && data.length > 0) ? "Y" : "";
  return (
    <>
      
      <table className={`table ${styles.supervisionTable}`}>
        <thead>
          <tr>
            <th colSpan={8} className="text-center">
              <h3>Centre supervision plan</h3>
              <h5>{pc.displayName}</h5>
              <h5>{term.displayName}, {term.termDates}</h5>
            </th>
          </tr>
          <tr>
            <th colSpan={5}>Day (eg Mon am, Mon pm)</th>            
            <th>Monday AM</th>
            <th>Tuesday AM</th>
            <th>Wednesday AM</th>
            <th>Thursday AM</th>
            <th>Friday AM</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={5}>Session time: start & end</th>
            <td>9:00-12:00</td>
            <td>9:00-12:00</td>
            <td>9:00-12:00</td>
            <td>9:00-12:00</td>
            <td>9:00-12:00</td>
            <td></td>
          </tr>
          <tr>
            <th colSpan={5}>Session type</th>
            <td>MA</td>
            <td>MA</td>
            <td>MA</td>
            <td>MA</td>
            <td>MA</td>
            <td></td>
          </tr>
          <tr>          
            <th colSpan={5}>Total # enrolled children</th>
            {totalKids.map((k, index) => (
              <td key={index}>{k}</td>
            ))}
            <td></td>
          </tr>
          <tr>
            <th colSpan={5}># under 2s on roll</th>
            {totalKidsUnder2.map((k, index) => (
              <td key={index}>{k}</td>
            ))}
            <td></td>
          </tr>
          <tr>
            <th colSpan={5}># regularly attending non-enrolled children</th>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td></td>
          </tr>
          <tr>
            <th>Adults (full name)</th>
            <th>Quals</th>
            <th>E or V</th>
            <th>First Aid Exp date</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {memberList.map((line, index) => (
            <tr key={line.member.uid} className={styles[line.member.rosterEducationName]}>              
              <td>{line.member.displayName}</td>
              <td>{line.member.rosterEducationName}</td>
              <td>V</td>
              <td>{line.member.firstAidExpiryDate}</td>
              <td></td>
              <td>{fillCell(line.monday)}</td>
              <td>{fillCell(line.tuesday)}</td>
              <td>{fillCell(line.wednesday)}</td>
              <td>{fillCell(line.thursday)}</td>
              <td>{fillCell(line.friday)}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
}

