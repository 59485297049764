import { useEffect } from "react";
import { Alert } from "react-bootstrap"
import AuthService from "../../services/auth.service";

const WaitingPlaycentreConfirmation = (props) => { 

  useEffect(() => {

  }, []);

  const onLogoutClick = async (event) => {
    event.preventDefault();
    AuthService.logout();
  };

  return (
    <>      
        <Alert key="success" variant="success">          
        Your login was successful, however you need to wait for the administrator to check your identity and assign a Playcentre for you.  < br />  < br />
        <Alert.Link href="/">Refresh</Alert.Link> the page to check if the administrator has assigned a Playcentre for you or <Alert.Link onClick={onLogoutClick}>logout</Alert.Link> and login again later.
      </Alert>    
    </>
  )
}

export { WaitingPlaycentreConfirmation };