import { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useParams } from 'react-router-dom';

import styles from './KidsDaysOfWeek.module.scss';
export default function KidsDaysOfWeek() {
  const { termYear, termIndex } = useParams();


  const [loading, setLoading] = useState(true);

  let [daysList, setDaysList] = useState([]);
  let [term, setTerm] = useState();
  let [pc, setPc] = useState();

  useEffect(() => {
    const loadMembers = () => {
      api
        .get(`/term/${termYear}/${termIndex}/kids/days_of_week`)
        .then((response) => {
          setDaysList(response.data.days);          
          setTerm(response.data.term);
          setPc(response.data.playcentre)
          setLoading(false);
        });
    };
    loadMembers();    

  }, [termYear, termIndex]);


  if (loading)
    return <Loading />;


  return (
    <>
      
      <table className={`table table-striped ${styles.kidsDays}`}>
        <thead>
          <tr>
            <th colSpan={8} className="text-center">
              <h3>Children days</h3>
              <h5>{pc.displayName}</h5>
              <h5>{term.displayName}, {term.termDates}</h5>
            </th>
          </tr>
          <tr>            
            <th></th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
          </tr>
        </thead>
        <tbody>
          {daysList.map((line, lineIndex) => (
            <tr key={line.lineNumber}>            
              <td>{line.lineNumber}</td>
              {line.kidByDay.map((kid, kidIndex) => (
                <td key={`${lineIndex}-${kidIndex}`} className={(styles[`age${kid?.years}`]) + ' ' + (kid?.isDropOff ? styles.dropOff : '')}>{kid?.displayName}</td>
                ))}              
            </tr>
          ))}
        </tbody>
      </table>

      <table className={`table table-bordered ${styles.kidsDays}`}>
        <tbody>
          <tr>
            <td colSpan={2}>CODES:</td>
            <td className={styles.age4}>4+ yr Olds</td>
            <td colSpan={2} className={styles.dropOff}>DROP OFF BUDDIES:</td>
          </tr>
          <tr>
            <td rowSpan={4}>0-2yrs old for Ratio's (15max)</td>
            <td>NEW*</td>
            <td className={styles.age3}>3yr Olds</td>
            <td></td>
            <td></td>
          </tr>
          <tr>          
            <td>Last term</td>
            <td className={styles.age2}>2yr Olds</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td rowSpan={2} className={styles.dropOff }>Drop Off's</td>
            <td className={styles.age1}>1yr Olds</td>
            <td></td>
            <td></td>
          </tr>
          <tr>          
            <td className={styles.age0}>0-12 months</td>
            <td></td>
            <td></td>
            </tr>
        </tbody>
      </table>

    </>
  );
}

