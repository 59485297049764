import { Navbar, NavbarBrand, NavbarToggler, } from 'reactstrap';
import { Link } from 'react-router-dom';

export { NavMenuSimple };

function NavMenuSimple() {
  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3">
        <NavbarBrand tag={Link} to="/">Playcentre Roster</NavbarBrand>     
        <NavbarToggler className="mr-2" />
      </Navbar>
    </header>
    );  
}
