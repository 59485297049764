import { Container } from 'reactstrap';
import { Outlet } from 'react-router-dom';

import NavMenu from './Nav/NavMenu';

export function Layout(props) {   
    return (
      <div>
        <NavMenu />
        <Container>
          <Outlet />
        </Container>
      </div>
    );  
}
