import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";

import MemberList from './components/Members/MemberList';
import MemberListForTerm from './components/Members/MemberListForTerm';
import MemberSupervisionPlan from './components/Members/MemberSupervisionPlan';
import MemberEdit from './components/Members/MemberEdit';
import MemberDaysChange from './components/Members/MemberDaysChange';
import MemberStaffDetails from './components/Members/MemberStaffDetails';

import KidsList from './components/Kids/KidsList';
import KidsByDay from './components/Kids/KidsByDay';
import KidsDaysOfWeek from './components/Kids/KidsDaysOfWeek'

import Roster from './components/Roster/Roster';
import RosterEdit from './components/Roster/RosterEdit';

const AppRoutes = [
  {
    //index: true,
    path: '/',
    element: <Home />
  },
  {
    path: '/term/:termYear/:termIndex',
    element: <Roster />
  },
  {
    path: '/term/:termYear/:termIndex/roster',
    element: <Roster />
  },
  {
    path: '/term/:termYear/:termIndex/roster/edit',
    element: <RosterEdit />
  },
  {
    path: '/term/:termYear/:termIndex/members/list',
    element: <MemberListForTerm />
  },
  {
    path: '/term/:termYear/:termIndex/members/supervision',
    element: <MemberSupervisionPlan />
  },
  {
    path: '/term/:termYear/:termIndex/members/days_change',
    element: <MemberDaysChange />
  },
  {
    path: '/term/:termYear/:termIndex/members/staff_details',
    element: <MemberStaffDetails />
  },


  {
    path: '/settings/members/list',
    element: <MemberList />
  },

  {
    path: '/settings/members/:memberUid/edit',
    element: <MemberEdit />
  },
  {
    path: '/settings/members/add',
    element: <MemberEdit />
  },


  {
    path: '/term/:termYear/:termIndex/kids/enrolment',
    element: <KidsByDay />
  },
  {
    path: '/term/:termYear/:termIndex/kids/days_of_weeks',
    element: <KidsDaysOfWeek />
  }  

];

export default AppRoutes;
