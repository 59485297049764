//import 'bootstrap/dist/css/bootstrap.css';
import './styles/bootstrap.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from "history";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

var reactPlugin = new ReactPlugin();
var clickPluginInstance = new ClickAnalyticsPlugin();
const browserHistory = createBrowserHistory({ basename: '' });
const clickPluginConfig = {
  autoCapture: true
};

var appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=4d83345d-6ceb-4f54-a516-b8d2afe5b7df;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/',
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    //extensions: [reactPlugin],
    // *** Add the Click Analytics plug-in. ***
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      // *** Add the Click Analytics plug-in. ***
      [clickPluginInstance.identifier]: clickPluginConfig
    }
  }
});
appInsights.loadAppInsights();


root.render(
  <AppInsightsErrorBoundary onError={() => <div className=" ms-3 mt-3"><p>
    An error occured at the page. <br/>
    Please try the following:<br />
    <ul>
      <li>Refresh the page</li>
      <li>Hard refresh the page (If you are on Windwows: press Ctrl + F5, or Shift + Ctrl + F5, if you are on Mac: press Cmd + F5 or Shift + Cmd + F5</li>
      <li>Go to the <a href="/">home page</a></li>
      <li>Try again later</li>
      <li>Contact the administrator about the issue</li>
    </ul>
  </p>
  </div>} appInsights={reactPlugin}>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </AppInsightsErrorBoundary>
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
