import React, { useEffect, useState } from 'react';
import { Nav, Collapse, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, NavLink } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import styles from './Nav.module.scss';
import api from '../../services/api'
import fileDownload from '../../services/fileDownload'
import * as CommonIcons from '../CommonIcons';
import AuthService from "../../services/auth.service";



export default function NavMenu() {
  const { termYear, termIndex } = useParams();  
  const navigate = useNavigate();  
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(true);
  const [terms, setTerms] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState({year: 0, index: 0});

  useEffect(() => {
    const loadTerms = () => {
      api
        .get(`/settings/terms/list`)
        .then((response) => {
          let list = response.data.termList;
          setTerms(list);

          if (!location.pathname.startsWith('/settings')) {
            if (!termYear || !termIndex || (Number(termYear) === 0 && Number(termIndex) === 0)) {
              if (response.data.currentTermUid && response.data.currentTermUid !== '') {
                let term = list.find(t => t.uid === response.data.currentTermUid);
                onTermClick(term);
              } else {
                onTermClick(list[0]);
              }
              //navigate(`/term/${list[0].year}/${list[0].index}/`);
            } else {
              let term = list.find(t => t.year === Number(termYear) && t.index === Number(termIndex));
              //setSelectedTerm(term);
              onTermClick(term);
            }
          }
        })
        .catch((err) => {
          console.log('trying to load terms', err);
          setTimeout(() => { loadTerms(); }, 5000);
        });;
    }

    loadTerms();
    
  }, []);


  const toggleNavbar = () => {
    //console.log('termYear ', termYear, ' termIndex ', termIndex)
    setCollapsed(!collapsed);
  };

  const onTermClick = (term) => {
    setSelectedTerm(term);
    //navigate(`/term/${term.year}/${term.index}`);
    
    if (location.pathname === '') {
      navigate(`/term/${term.year}/${term.index}`);
    }


    if (location.pathname.startsWith('/term')) {
      let match = location.pathname.match(/\/term\/\d+\/\d+\//);
      if (match) {
        let excludedTerm = location.pathname.substring(match[0].length - 1);
        console.log(excludedTerm);
        navigate(`/term/${term.year}/${term.index}${excludedTerm}`);
      }
    }

    //console.log(useParams());

    //const year = term.year;
    //const index = term.index;
    //console.log(props);

    //const path = generatePath(props.match.path, { year, index });
    //console.log(path);
  };

  const onLogoutClick = async (event) => {
    event.preventDefault();
    AuthService.logout();
  };

  return (
    <header>
      <Navbar className={`${styles.navigationHeader} navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3`} container light>
        <NavbarBrand tag={Link} to="/">Playcentre Roster - {AuthService.getLoggedInUser()?.playcentre?.displayName}</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex " isOpen={!collapsed} navbar>
          <Nav className="me-auto" navbar>
            {terms && selectedTerm &&
              <>                
                <NavLink className="align-self-center ms-5">Active term:</NavLink>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {selectedTerm.displayName}
                  </DropdownToggle>
                  <DropdownMenu end>
                    {
                      terms.map(term => (<DropdownItem key={term.uid} onClick={() => onTermClick(term)}>{term.displayName}</DropdownItem>))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            }
          </Nav>

          <ul className="navbar-nav flex-grow">
            {selectedTerm &&
              <>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Roster
                  </DropdownToggle>
                  <DropdownMenu end>
                  <DropdownItem tag={Link} className="text-dark" to={`/term/${selectedTerm.year}/${selectedTerm.index}/roster`}>View roster for {selectedTerm.displayName}</DropdownItem>
                  {AuthService.getLoggedInUser()?.permissions?.canEditRoster &&
                    <DropdownItem tag={Link} className="text-dark" to={`/term/${selectedTerm.year}/${selectedTerm.index}/roster/edit`}>Edit roster for {selectedTerm.displayName}</DropdownItem>
                  }
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Members
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem tag={Link} className="text-dark" to={`/term/${selectedTerm.year}/${selectedTerm.index}/members/list`}>Contact list</DropdownItem>
                    <DropdownItem tag={Link} className="text-dark" to={`/term/${selectedTerm.year}/${selectedTerm.index}/members/supervision`}>Centre supervision plan</DropdownItem>
                    <DropdownItem tag={Link} className="text-dark" to={`/term/${selectedTerm.year}/${selectedTerm.index}/members/staff_details`}>Staff details</DropdownItem>
                    <DropdownItem tag={Link} className="text-dark" to={`/term/${selectedTerm.year}/${selectedTerm.index}/members/days_change`}>Days change report</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag={Button} className="text-dark" onClick={() => fileDownload(`/term/${selectedTerm.year}/${selectedTerm.index}/members/change_of_days/download`)}>Download 'Change of days of enrolment' for {selectedTerm.displayName}</DropdownItem>
                    <DropdownItem tag={Button} className="text-dark" onClick={() => fileDownload(`/term/${selectedTerm.year}/${selectedTerm.index}/members/supervision_plan/download`)}>Download 'Supervision plan' for {selectedTerm.displayName}</DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem tag={Link} className="text-dark" to={`/settings/members/list`}>Edit playcentre members</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Kids
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem tag={Link} className="text-dark" to={`/term/${selectedTerm.year}/${selectedTerm.index}/kids/enrolment`}>Enrolment report</DropdownItem>
                    <DropdownItem tag={Link} className="text-dark" to={`/term/${selectedTerm.year}/${selectedTerm.index}/kids/days_of_weeks`}>Children days</DropdownItem>
                    {/*<DropdownItem>Children birthdays</DropdownItem>*/}
                    <DropdownItem divider />
                    <DropdownItem tag={Button} className="text-dark" onClick={() => fileDownload(`/term/${selectedTerm.year}/${selectedTerm.index}/kids/daybook/download`)}>Download 'Day book' for {selectedTerm.displayName}</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            }
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <CommonIcons.Account className={styles.accountIcon} />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag={Link} className="text-dark" disabled>{AuthService.getLoggedInUser()?.displayName}</DropdownItem>
                <DropdownItem divider />
                {/*<DropdownItem tag={Link} className="text-dark" to={`/profile`}>Profile</DropdownItem>*/}
                <DropdownItem tag={Button} className="text-dark" onClick={onLogoutClick}>Logout</DropdownItem>
              </DropdownMenu>
             
            </UncontrolledDropdown>
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*  <NavLink tag={Link} className="text-dark" to="/kids/list">Kids by day</NavLink>*/}
            { /*Kids days with colors and columns - name of the day*/}
            {/*</NavItem>*/}            
            {/*<NavItem>*/}
            {/*  <NavLink tag={Link} className="text-dark" to="/">Child details</NavLink>*/}
            { /* Chils, age with month, days sorded by age desc, with days: 9.15-11.45 or empty */}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*  <NavLink tag={Link} className="text-dark" to="/">Children birthdays</NavLink>*/}
            { /* kids split in groups of full years order by birthday */}
            {/* </NavItem>*/}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
}

