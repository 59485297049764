import { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useParams } from 'react-router-dom';


export default function MemberDaysChange() {
  const { termYear, termIndex } = useParams();


  const [loading, setLoading] = useState(true);

  let [memberList, setMemberList] = useState([]);
  let [term, setTerm] = useState();
  let [pc, setPc] = useState();

  useEffect(() => {
    const loadMembers = () => {
      api
        .get(`/term/${termYear}/${termIndex}/members/days_change`)
        .then((response) => {
          setMemberList(response.data.members);          
          setTerm(response.data.term);
          setPc(response.data.playcentre);

          setLoading(false);
        });
    };
    loadMembers();    

  }, [termYear, termIndex]);


  if (loading)
    return <Loading />;

  //const fillCell = (data) => (data && data.length > 0) ? data : "";
  const fillCell = (data) => {
    switch (data) {
      case 0: return "";
      case 1: return "Added";
      case 2: return "x";
      case 3: return "Removed";
      default:
    }
  };

  return (
    <>
      
      <table className="table table-striped">
        <thead>
          <tr>
            <th colSpan={8} className="text-center">
              <h3>Day change report</h3>
              <h5>{pc.displayName}</h5>
              <h5>{term.displayName}, {term.termDates}</h5>
            </th>
          </tr>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Qualification</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
          </tr>
        </thead>
        <tbody>
          {memberList.map((line, index) => (
            <tr key={line.member.uid}>
              <td>{index + 1}</td>
              <td>{line.member.displayName}</td>
              <td>{line.member.rosterEducationName}</td>
              <td>{fillCell(line.monday)}</td>
              <td>{fillCell(line.tuesday)}</td>
              <td>{fillCell(line.wednesday)}</td>
              <td>{fillCell(line.thursday)}</td>
              <td>{fillCell(line.friday)}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
}

