import styles from './Static.module.scss';

const TnC = () => {

  return (
    <>
      <h1 className={`mb-3 text-center ${styles.header}`}>Terms and conditions</h1>    
      <p className="mt-3">
        These Terms and Conditions ("Terms") govern your use of this website (the "Website") and any related services provided by Exact IOT Limited (the "Service"). By accessing or using the Website or the Service, you agree to be bound by these Terms.
      </p>
      <p className="mt-4 mb-4">Last Updated: 01 April 2024</p>

      <h4>1. Account Registration</h4>
      <p className="mt-3">
        1.1. In order to access certain features of the Service, you may be required to register for an account. When registering for an account, you agree to provide accurate and complete information.
      </p>
      <p className="mt-3">
        1.2. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
      </p>

      <h4>2. Subscription Fees</h4>
      <p className="mt-3">
        2.1. Certain features of the Service may require payment of subscription fees. By subscribing to the Service, you agree to pay the applicable fees as described on the Website.
      </p>
      <p className="mt-3">
        2.2. Subscription fees are billed on a monthly or yearly basis, depending on the subscription plan.
      </p>
      <p className="mt-3">
        2.3. All fees are non-refundable unless otherwise stated in these Terms or as required by law.
      </p>

      <h4>3. User Content</h4>
      <p className="mt-3">
        3.1. Our Service allows you to create and publish roster schedules and other content ("User Content"). You retain ownership of any intellectual property rights that you hold in the User Content.
      </p>
      <p className="mt-3">
        3.2. By creating and publishing User Content, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content solely for the purpose of providing and improving the Service, including but not limited to support, bug fixes, and maintenance.
      </p>


      <h4>4. Prohibited Conduct</h4>
      <p className="mt-3">
        4.1. You agree not to engage in any of the following prohibited activities:
        <ul>
          <li>Violating any laws or regulations;</li>
          <li>Interfering with or disrupting the Service or servers or networks connected to the Service;</li>
          <li>Impersonating any person or entity, or falsely stating or otherwise misrepresenting your affiliation with a person or entity;</li>
          <li>Collecting or storing personal data about other users without their consent;</li>
          <li>Using the Service for any unlawful or fraudulent purpose.</li>
        </ul>
      </p>


      <h4>5. Limitation of Liability</h4>
      <p className="mt-3">
        5.1. To the fullest extent permitted by applicable law, Exact IOT Limited shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
        <ul>
          <li>Your access to or use of or inability to access or use the Service;</li>
          <li>Any conduct or content of any third party on the Service;</li>
          <li>Any content obtained from the Service; and</li>
          <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
        </ul>
      </p>

      <h4>6. Changes to Terms</h4>
      <p className="mt-3">
        6.1. We reserve the right to modify these Terms at any time. If we make changes to these Terms, we will post the revised Terms on the Website and update the "Last Updated" date at the top of these Terms. Your continued use of the Service after the posting of the revised Terms will constitute your acceptance of the revised Terms.
      </p>

      <h4>7. Governing Law</h4>
      <p className="mt-3">
        7.1. These Terms shall be governed by and construed in accordance with the laws of New Zealand, without regard to its conflict of law principles.
      </p>

      <h4>8. Contact Us</h4>
      <p className="mt-3">
        8.1. If you have any questions about these Terms, please contact us at <a href="mailto:support@exact-iot.com">support@exact-iot.com</a>
      </p>
    </>
  );
}

export { TnC };