import React, { useEffect, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { LayoutAuth } from './components/Layouts/LayoutAuth';
import { LayoutStatic } from './components/Layouts/LayoutStatic';

import './custom.css';
import AuthService from "./services/auth.service";
import { useNavigate } from "react-router-dom";

import { Login } from './components/Auth/Login';
import { Register } from './components/Auth/Register';
import { ForgotPasswordRequest } from './components/Auth/ForgotPasswordRequest'
import { SetNewPassword } from './components/Auth/SetNewPassword'
import { WaitingPlaycentreConfirmation } from './components/Auth/WaitingPlaycentreConfirmation'

import { Privacy } from './components/Static/Privacy';
import { TnC } from './components/Static/TnC';

function App(props) {
  let navigate = useNavigate();


  useEffect(() => {
    if (AuthService.shouldRedirectToLogin()) {
      navigate("/login");
    }
  });


  return (
    <Suspense fallback="loading...">
      <Routes>
        <Route element={<LayoutAuth />}>
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/forgot-password' element={<ForgotPasswordRequest />} />
          <Route exact path='/set-password/:token' element={<SetNewPassword />} />
          <Route exact path='/waiting' element={<WaitingPlaycentreConfirmation />} />
        </Route>

        <Route element={<LayoutStatic />}>        
          <Route exact path='/privacy-policy' element={<Privacy />} />
          <Route exact path='/tnc' element={<TnC />} />
        </Route>

        <Route element={<Layout />}>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} element={element} exact {...rest} />;
          })}
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;